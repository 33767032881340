
.poemAuthor {
  text-align: center;
  font-size: 16px;
  font-style: italic;
}

.poemLink {
  margin-bottom: 50px;
  text-align: center;
  font-size: 14px;
}

.pageContainer {
  display: block;
  background-color: #0f0f0f;
}

a {
  color: #fafafa;
}

a:hover {
  color: #edb2e5;
}

.MuiInputBase-input {
  color: #fafafa;
  border-color: #fafafa;
}

.base { 
  fill: #fafafa;
  font-family: "Montserrat";
  font-size: 22
}

#market-interface-container p{
  font-size: 12px !important;
}